<template>
  <div v-once class="banners">
    <CImage
      class="banners__img"
      src="foto_vashego_buketa.webp"
      fallback="foto_vashego_buketa.png"
      alt="Фото вашего букета перед доставкой"
    />
    <CImage
      class="banners__img"
      src="dostavka_ot_2kh_chasov.webp"
      fallback="dostavka_ot_2kh_chasov.png"
      alt="Доставка от 2-х часов, днем бесплатно"
    />
    <CImage
      class="banners__img"
      src="svezhiye_tsvety.webp"
      fallback="svezhiye_tsvety.png"
      alt="100% свежие цветы или заменим букет"
    />
  </div>
</template>

<script>
import CImage from '@/components/common/CImage'

export default {
  components: {
    CImage,
  },
}
</script>

<style lang="scss" scoped>
.banners {
  display: flex;
  margin: 0 rem(-9) rem(18) rem(-9);
  &__img {
    min-width: calc(33.333% - 1.125rem);
    max-width: calc(33.333% - 1.125rem);
    min-height: rem(108);
    margin: 0 rem(9);
  }
}
</style>
