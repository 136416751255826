export const createDeliveryModal = async () => {
  const { default: asyncDeliveryModal } = await import(
    /* webpackChunkName: 'delivery-modal' */ '@/components/modals/DeliveryModal'
  )
  return asyncDeliveryModal
}

export const createCallbackModal = async () => {
  const { default: asyncCallbackModal } = await import(
    /* webpackChunkName: 'callback-modal' */ '@/components/modals/CallbackModal'
  )
  return asyncCallbackModal
}
