<template>
  <div v-once class="box">
    <div class="box__block">
      <h2 class="box__block-title box__block-title--black">
        <nuxt-link class="box__block-link" to="/">Все цветы</nuxt-link>
      </h2>
      <ul class="box__block-list">
        <li class="box__block-item">
          <nuxt-link class="box__block-href" to="/">
            Авторские букеты
          </nuxt-link>
        </li>
        <li class="box__block-item">
          <nuxt-link class="box__block-href" to="/">Композиции</nuxt-link>
        </li>
        <li class="box__block-item">
          <nuxt-link class="box__block-href" to="/"> Букеты из роз </nuxt-link>
        </li>
      </ul>
      <button type="button" class="box__block-btn box__block-btn--green">
        Выбрать цветы
      </button>
    </div>
    <nuxt-link to="/" class="box__block">
      <h3 class="box__block-title">Специальные предложения</h3>
      <button type="button" class="box__block-btn">Смотреть</button>
    </nuxt-link>
    <nuxt-link to="/" class="box__block">
      <h3 class="box__block-title">Цветочные композиции</h3>
      <button type="button" class="box__block-btn">Смотреть</button>
    </nuxt-link>
    <nuxt-link to="/" class="box__block">
      <h3 class="box__block-title">Букеты из роз</h3>
      <button type="button" class="box__block-btn">Смотреть</button>
    </nuxt-link>
    <nuxt-link to="/" class="box__block">
      <h3 class="box__block-title">Хиты продаж</h3>
      <button type="button" class="box__block-btn">Смотреть</button>
    </nuxt-link>
  </div>
</template>

<script>
export default {
  name: 'PromoBlocks',
}
</script>

<style lang="scss" scoped>
@mixin base-before() {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}
.box {
  display: grid;
  grid-row-gap: rem(18);
  grid-column-gap: rem(18);
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(4, 1fr);
  &__block {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: rem(16) rem(22);
    text-decoration: none;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: rem(2);
    &:nth-child(1) {
      grid-area: 1 / 1 / 3 / 3;
      min-height: rem(342);
      padding: rem(22);
      background: rgb(227, 238, 255);
      background: linear-gradient(
        180deg,
        rgba(227, 238, 255, 1) 0%,
        rgba(243, 230, 234, 1) 100%
      );
      &:before {
        background-repeat: no-repeat;
        background-position: center rem(135);
        background-size: 90%;

        @include background-image('flowers.png', 'flowers.webp');
        @include base-before();
      }
    }
    &:nth-child(2) {
      grid-area: 1 / 3 / 2 / 4;
      background: rgb(128, 80, 194);
      background: linear-gradient(
        90deg,
        rgba(128, 80, 194, 1) 0%,
        rgba(188, 154, 236, 1) 100%
      );
      &:before {
        background-repeat: no-repeat;
        background-position: 200% -105%;

        @include base-before();
        @include background-image('flowers_2.png', 'flowers_2.webp');
      }
    }
    &:nth-child(3) {
      grid-area: 1 / 4 / 2 / 5;
      background: rgb(224, 110, 110);
      background: linear-gradient(
        90deg,
        rgba(224, 110, 110, 1) 0%,
        rgba(254, 179, 178, 1) 100%
      );
      &:before {
        background-repeat: no-repeat;
        background-position: 150% 36%;

        @include base-before();
        @include background-image('flowers_4.png', 'flowers_4.webp');
      }
    }
    &:nth-child(4) {
      grid-area: 2 / 3 / 3 / 4;
      background: rgb(111, 164, 221);
      background: linear-gradient(
        90deg,
        rgba(111, 164, 221, 1) 0%,
        rgba(154, 203, 255, 1) 100%
      );
      &:before {
        background-repeat: no-repeat;
        background-position: 132% -48%;

        @include base-before();
        @include background-image('flowers_3.png', 'flowers_3.webp');
      }
    }
    &:nth-child(5) {
      grid-area: 2 / 4 / 3 / 5;
      background: rgb(225, 115, 176);
      background: linear-gradient(
        90deg,
        rgba(225, 115, 176, 1) 0%,
        rgba(255, 197, 235, 1) 100%
      );
      &:before {
        background-repeat: no-repeat;
        background-position: 186% -127%;

        @include base-before();
        @include background-image('flowers_5.png', 'flowers_5.webp');
      }
    }
    &:not(:first-child):hover {
      .box__block-title {
        text-decoration: underline;
      }
    }

    &-title {
      position: relative;
      z-index: 1;
      color: #fff;
      font-weight: 900;
      font-size: rem(22);
      line-height: rem(24.5);
      &--black {
        align-self: flex-start;
        width: 100%;
        color: $text-color;
        font-size: rem(36);
        text-align: center;
      }
    }
    &-link {
      color: inherit;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &-btn {
      position: relative;
      z-index: 1;
      display: inline-flex;
      align-items: center;
      align-self: flex-start;
      justify-content: center;
      min-height: rem(32);
      margin-top: auto;
      padding: 0 rem(30);
      color: #fff;
      font-weight: 700;
      font-size: rem(11);
      line-height: 1;
      text-transform: uppercase;
      border: rem(1) solid #fff;
      border-radius: rem(16);
      &--green {
        margin: auto auto 0 auto;
        color: $color-green;
        background-color: #fff;
        border-color: $color-green;
      }
    }
    &-list {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: rem(14);
      padding: 0;
    }
    &-href {
      color: $text-color;
      font-size: rem(13.5);
      line-height: 1;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &-item {
      display: inline-flex;
      align-items: center;
      line-height: 1;
      &:not(:first-child):before {
        display: inline-block;
        width: rem(1);
        min-height: rem(22.5);
        margin: 0 rem(10);
        background-color: $color-green;
        content: '';
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-desktop) {
  .box {
    &__block {
      min-height: rem(162);
      &-list {
        flex-direction: column;
      }
      &-item {
        margin-bottom: rem(8);
        &:not(:first-child):before {
          display: none;
        }
      }
      &:nth-child(1) {
        &:before {
          background-position: center rem(165);
        }
      }
      &:nth-child(2) {
        grid-area: 1 / 3 / 2 / 5;
        &:before {
          background-position: 107% -105%;
        }
      }
      &:nth-child(3) {
        grid-area: 2 / 3 / 3 / 5;
        &:before {
          background-position: 103% 36%;
        }
      }
      &:nth-child(4) {
        grid-area: 3 / 1 / 4 / 3;
        &:before {
          background-position: 102% -48%;
        }
      }
      &:nth-child(5) {
        grid-area: 3 / 3 / 4 / 5;
        &:before {
          background-position: 113% -127%;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-tablet) {
  .box {
    &__block {
      min-height: rem(150);
      &:nth-child(1) {
        min-height: rem(150);
      }
      &:nth-child(2) {
        &:before {
          background-position: 107% -50%;
        }
      }
      &:nth-child(4) {
        &:before {
          background-position: 102% -20%;
        }
      }
      &:nth-child(5) {
        &:before {
          background-position: 122% -61%;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-large-mobile) {
  .box {
    display: flex;
    flex-wrap: wrap;
    grid-row-gap: unset;
    grid-column-gap: unset;
    grid-template-rows: unset;
    grid-template-columns: unset;
    margin: 0 rem(-4.5);
    &__block {
      width: 100%;
      max-width: calc(50% + #{rem(-9)});
      margin: 0 rem(4.5) rem(9) rem(4.5);
      padding: rem(8) rem(11);
      overflow: hidden;
      &-title {
        font-size: 18px;
        line-height: 1.2;
        &--black {
          font-size: 30px;
        }
      }
      &-list {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      }
      &-item {
        margin: 0 rem(9) rem(9) rem(9);
      }
      &:nth-child(1) {
        max-width: 100%;
        min-height: rem(245);
        padding: rem(8) rem(11);
        &:before {
          background-position: center rem(70);
        }
      }
      &:nth-child(2) {
        &:before {
          left: rem(20);
          background-position: right rem(30);
        }
      }
      &:nth-child(3) {
        &:before {
          left: rem(10);
          background-position: right 0;
          background-size: rem(160);
        }
      }
      &:nth-child(4) {
        &:before {
          left: rem(6);
          background-position: right rem(20);
        }
      }
      &:nth-child(5) {
        &:before {
          left: rem(28);
          background-position: right rem(20);
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-mobile) {
  .box {
    &__block {
      max-width: 100%;
      &:nth-child(1) {
        &:before {
          background-position: center rem(90);
        }
      }
    }
  }
}
</style>
