<template>
  <div class="promo">
    <PromoDelivery v-if="isTablet" />

    <h1 class="promo__title">{{ title }}</h1>

    <PromoImages v-if="isDesktop" />

    <PromoBlocks />
  </div>
</template>

<script>
import PromoBlocks from '@/components/Promo/PromoBlocks'
import PromoDelivery from '@/components/Promo/PromoDelivery'
import PromoImages from '@/components/Promo/PromoImages'

export default {
  name: 'Promo',

  components: {
    PromoImages,
    PromoBlocks,
    PromoDelivery,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
  },

  computed: {
    isDesktop() {
      return !this.$screen?.tabletAndDown ?? false
    },
    isTablet() {
      return this.$screen?.tabletAndDown ?? false
    },
  },
}
</script>

<style lang="scss" scoped>
.promo {
  &__title {
    margin: rem(18) 0 0 0;
    margin-bottom: rem(18);
    font-weight: 500;
    text-align: center;
  }
}
</style>
