<template>
  <div class="delivery" @click="openDeliveryModal">
    <h3 class="delivery__title">Укажите город доставки!</h3>
    <p class="delivery__description">
      От этого зависит выбор цветов и их стоимость.
    </p>
    <DeliveryForm />

    <portal to="layout">
      <component :is="modal" @close="modal = null" />
    </portal>
  </div>
</template>

<script>
import DeliveryForm from '@/components/ui/DeliveryForm'
import { createDeliveryModal } from '@/components/modals/index'

export default {
  name: 'PromoDelivery',

  components: {
    DeliveryForm,
  },

  data: () => ({
    modal: null,
  }),

  methods: {
    async openDeliveryModal() {
      this.modal = await createDeliveryModal()
    },
  },
}
</script>

<style lang="scss" scoped>
.delivery {
  margin: rem(24) 0;
  padding: rem(26) rem(28) rem(32) rem(28);
  background-color: $light-green;
  border-radius: rem(5);
  &__title {
    color: $color-green;
    font-size: rem(22);
  }
  &__description {
    color: $color-green;
    font-size: rem(16);
  }
}
</style>
