<template>
  <div class="form">
    <input class="form__input" type="text" readonly value="Москва и МО" />
    <CButton class="form__btn" type="button" icon>
      <CIcon name="search" class="form__btn-icon" />
    </CButton>
  </div>
</template>

<script>
import CButton from '@/components/common/CButton'
import CIcon from '@/components/common/CIcon'

export default {
  components: {
    CButton,
    CIcon,
  },
}
</script>

<style lang="scss" scoped>
.form {
  position: relative;
  width: 100%;
  &__input {
    width: 100%;
    height: 44px;
    padding: rem(6) rem(12);
    border: rem(1) solid $color-gray3;
    border-radius: rem(5);
  }
  &__btn {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    min-width: 44px;
    border-radius: unset;
    border-top-right-radius: rem(5);
    border-bottom-right-radius: rem(5);
    box-shadow: unset;
    &-icon {
      fill: #fff;
      @include base-width(20);
    }
  }
}
</style>
